<template>
  <div class="settingsDashboard">
    <div class="settingsDashboard__left">
      <div class="settingsDashboard__title">Password</div>
      <div class="settingsDashboard__form">
        <form
          class="form form--changePassword js-changePassword js-ajaxForm"
          :class="{'loading' : loading}"
          id="changePasswordForm"
          @submit.prevent="submitHandler"
        >
          <label class="form__label form__label--password">
            <div class="form__caption">Old profile password:</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                :type="switchedIcons.oldPassword.type"
                :disabled="loading"
                v-model.trim="oldPassword"
                autocomplete="off"
                placeholder="Enter old password"
                spellcheck="false"
              />
              <div
                class="form__icon js-openPass"
                :class="{'openPassword' : switchedIcons.oldPassword.status}"
                @click="changeVisible('oldPassword')"
              >
                <span class="form__icon-open"><SvgIcon name="eye-open"/></span>
                <span class="form__icon-close"><SvgIcon name="eye-close"/></span>
              </div>
              <div v-if="$v.oldPassword.$dirty && !$v.oldPassword.required" class="form__error">This field is required
              </div>
            </div>
          </label>
          <label class="form__label form__label--password">
            <div class="form__caption">New profile password:</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                :type="switchedIcons.newPassword.type"
                :disabled="loading"
                v-model.trim="newPassword"
                autocomplete="off"
                placeholder="Enter new password"
              />
              <div
                class="form__icon js-openPass"
                :class="{'openPassword' : switchedIcons.newPassword.status}"
                @click="changeVisible('newPassword')"
              >
                <span class="form__icon-open"><SvgIcon name="eye-open"/></span>
                <span class="form__icon-close"><SvgIcon name="eye-close"/></span>
              </div>
              <div v-if="$v.newPassword.$dirty && !$v.newPassword.required" class="form__error">This field is required
              </div>
              <div v-if="!$v.newPassword.minLength" class="form__error">min length
                {{$v.newPassword.$params.minLength.min}} symbols
              </div>
            </div>
          </label>
          <label
            class="form__label form__label--confirm"
            :class="[
              {'invalid' : confirmInvalid},
              {'disabled' : !$v.newPassword.required || !$v.newPassword.minLength}
            ]"
          >
            <div class="form__caption">Confirm new password:</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                :type="switchedIcons.confirmPassword.type"
                :disabled="!$v.newPassword.required || !$v.newPassword.minLength || loading"
                v-model.trim="confirmPassword"
                autocomplete="off"
                placeholder="Confirm new password"
                @input="confirmSamePassword"
              />
              <div
                class="form__icon js-openPass"
                :class="{'openPassword' : switchedIcons.confirmPassword.status}"
                @click="changeVisible('confirmPassword')"
              >
                <span class="form__icon-open"><SvgIcon name="eye-open"/></span>
                <span class="form__icon-close"><SvgIcon name="eye-close"/></span>
              </div>
              <div v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required" class="form__error">This field is
                required
              </div>
              <div v-if="confirmInvalid" class="form__error">passwords do not match</div>
            </div>
          </label>
          <div class="form__label form__label--button disabled">
            <Spinner/>
            <button class="form__button" :disabled="!displayButton || loading">
              <span class="text">Change password</span>
            </button>
          </div>
        </form>
      </div>
    </div>
     <div class="settingsDashboard__right">
      <div class="settingsDashboard__title">Alerts</div>
      <div class="settingsDashboard__form">
        <form class="form form--typeAlerts js-ajaxForm" id="typeAlertsForm">
          <label class="form__label form__label--checkbox">
            <input
              class="form__checkbox"
              type="checkbox"
              v-model="type"
              value="2"
              @change="changeAlerts"
            />
            <i class="form__checkbox-icon"></i>
            <span class="form__checkbox-box">Receive in-application alerts and notifications</span>
          </label>
          <label class="form__label form__label--checkbox">
            <input
              class="form__checkbox"
              type="checkbox"
              v-model="type"
              value="4"
              @change="changeAlerts"
            />
            <i class="form__checkbox-icon"></i>
            <span class="form__checkbox-box">Receive email alerts and notifications</span>
          </label>
          <label class="form__label form__label--checkbox">
            <input
              class="form__checkbox"
              type="checkbox"
              v-model="type"
              value="8"
              @change="changeAlerts"
            />
            <i class="form__checkbox-icon"></i>
            <span class="form__checkbox-box">Receive text message alerts and notifications</span>
          </label>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { required, minLength } from 'vuelidate/lib/validators'
  import './Settings.styl'

  export default {
    name: 'Settings',
    data: () => ({
      type: [],
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirmInvalid: false,
      displayButton: false,
      switchedIcons: {
        oldPassword: {
          status: false,
          type: 'password',
        },
        newPassword: {
          status: false,
          type: 'password',
        },
        confirmPassword: {
          status: false,
          type: 'password',
        },
      },
      loading: false,
    }),
    validations: {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
      },
    },
    async created () {
      const checkAllertsCount = await this.$store.dispatch('getNotChannel');
      await this.showCheckedAlerts(checkAllertsCount);
    },
    mounted () {
      this.$root.fields.changeChecked('.form__checkbox')
    },
    methods: {
      changeVisible (elem) {
        for (const key in this.switchedIcons) {
          if (elem === key) {
            this.switchedIcons[elem].status = !this.switchedIcons[elem].status
            this.switchedIcons[elem].type === 'password' ? this.switchedIcons[elem].type = 'text'
              : this.switchedIcons[elem].type = 'password'
          }
        }
      },
      confirmSamePassword () {
        if (this.confirmPassword === this.newPassword) {
          this.confirmInvalid = false
          this.displayButton = true
        } else {
          this.confirmInvalid = true
          this.displayButton = false
        }
      },
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        this.loading = true

        const data = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }

        const result = await this.$store.dispatch('changePassword', data)

        if (result === true) {
          const message = {
            title: 'Password Changed',
            text: 'Your password has been changed successfully',
          }

          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
      },
      async changeAlerts () {
        await this.$store.dispatch('setAlerts', this.type.length > 0 ? this.type.reduce((prev, next) => `${+prev + +next}`) : '0')
      },
      showCheckedAlerts (count) {
        count === 2 ? this.type = ['2'] : count === 4 ? this.type = ['4'] : count === 6 ? this.type = ['2', '4'] : count === 8 ? this.type = ['8'] : count === 10 ? this.type = ['2', '8'] : count === 12 ? this.type = ['4', '8'] : count === 14 ? this.type = ['2', '4', '8'] : this.type = []
        this.$root.fields.getFields('.form__checkbox').forEach(field => {
          this.type.forEach(value => {
            // eslint-disable-next-line no-unused-expressions
            +field.value === +value ? field.checked = true : null;
          })
        })
        this.$root.fields.changeChecked('.form__checkbox')
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
